export const PAGE_SIZE = 50;

export const STAGE = import.meta.env.VITE_PORTAL_STAGE || 'offline';
// const region = import.meta.env.VITE_PORTAL_REGION || 'us-west-2'

export const isTest = import.meta.env.NODE_ENV === 'test';
export const isProd = STAGE === 'prod';
export const isDev = ['dev', 'deva'].includes(STAGE);
export const isOffline = STAGE === 'offline';

export const API_URL =
	location.host === 'network.dev.lmno.cloud'
		? 'https://api2.lmno.care/prod/data'
		: import.meta.env.VITE_API_URL || 'http://localhost:3000/offline/data';

export const FILESTACK_API_KEY = import.meta.env.VITE_FILESTACK_API_KEY || 'AcfEeZTHeQ74jtWR5CxPcz';
// This is FileStack's upload destination; it's both a bucket and a domain name.
export const FILE_BUCKET = import.meta.env.VITE_FILE_BUCKET || 'fs-deva.lmno.cloud';
// This is the bucket and domain name for offline content not uploaded using FileStack.
export const OFFLINE_FILE_BUCKET = 'elemeno-offline-bucket';
export const SEGMENT_WRITE_KEY = import.meta.env.VITE_SEGMENT_WRITE_KEY || '';
export const INLINE_NONCE = import.meta.env.VITE_NONCE || (isOffline ? 'OFFLINE' : 'eh-testing');

export const SSO_REDIRECT_URL = window.location.origin;

export const SENTRY_DSN =
	import.meta.env.VITE_SENTRY_DSN || 'https://523178ddb16c41f8bc088d82dd865636@o38001.ingest.us.sentry.io/6507632';

export const AWS_COGNITO_URL =
	import.meta.env.VITE_AWS_COGNITO_URL || // this should be set for prod only
	// 'https://authservice.lmno.cloud'; // TODO: switch over to the nice domain, but Google needs to be reconfigured to support it
	'https://elemeno-dev.auth.us-east-1.amazoncognito.com'; // else, use deva cognito service

export const ADMIN_SSO_CLIENT_ID =
	import.meta.env.VITE_ADMIN_SSO_CLIENT_ID || // this should be set for prod only
	'1jaj3ls99ujua0cstq3e1vcjb9'; // else, use dev-account elemeno-admin-dev cognito client

export const ADMIN_SSO_CLIENT_SECRET = undefined; // secret appears to now be required for Cognito Apps, but is not currently set for the default admin cognito app

export const APP_VERSION = {
	branch: isOffline ? 'OFFLINE_BRANCH' : import.meta.env.VITE_GIT_BRANCH,
	tag: isOffline ? 'OFFLINE_TAG' : import.meta.env.VITE_GIT_TAG,
	commit: isOffline ? 'OFFLINE_COMMIT' : import.meta.env.VITE_COMMIT,
	short: isOffline ? 'OFFLINE_SHORT' : import.meta.env.VITE_COMMIT?.substring(0, 7),
	timestamp: isOffline ? Date.now() : import.meta.env.VITE_BUILD_TIMESTAMP,
	prNumber: isOffline ? 'OFFLINE_NUM' : import.meta.env.VITE_GIT_PR_NUMBER,
	prPath:
		isOffline || STAGE !== 'prs' || !import.meta.env.VITE_GIT_PR_PATH ? '' : '/' + import.meta.env.VITE_GIT_PR_PATH,
	release: isOffline ? 'OFFLINE_RELEASE' : import.meta.env.VITE_RELEASE_VERSION,
};

const PR_PATH = window.location.origin.includes('.prs.') ? APP_VERSION.prPath : '';
export const APP_ROOT = window.location.origin + (PR_PATH || '');

export const APP_BASE_URL = import.meta.env.VITE_APP_BASE_URL || 'dev.lmno.cloud';
export const ADMIN_BASE_URL = import.meta.env.VITE_ADMIN_BASE_URL || 'dev.lmno.cloud'; // the same in dev, but not prod
export const ADMIN_URL = isOffline ? 'http://localhost:3006' : `https://admin.${ADMIN_BASE_URL}`;
export const STUDIO_URL = isOffline ? 'http://localhost:3333' : `https://studio.${ADMIN_BASE_URL}`;

// paths

export const PATH_ROOT = '/';
export const PATH_ERROR = '/error';
export const PATH_ERROR_TEST = '/errortest';
export const PATH_TEST = '/test';
export const PATH_CAMPAIGN = '/c/:campaignId/*';

export const PATH_LOGIN = '/login';
export const PATH_PASSWORD = '/password';
export const PATH_REGISTER = '/register';
export const PATH_REGISTERNAME = '/register/name';
export const PATH_MAGICLINK = '/link-sent';
export const PATH_CONFIRM = '/confirm';
export const PATH_SETPASSWORD = '/setpassword';
export const PATH_SETNAME = '/setname';
export const PATH_SETTEAM = '/setteam';
export const PATH_ADMIN = '/admin';
export const PATH_LOGINSSO = '/loginsso'; // TODO: hidden sso login page
export const PATH_REQUEST_ACCESS = '/request_access';
export const PATH_SELECT_SITE = '/selectsite';
export const PATH_STUDENT_LOGIN = '/student';

export const PATH_UNSUBSCRIBE = '/unsubscribe';

export const PATH_HOME = '/home';
export const PATH_BOOKMARKS = '/bookmarks';
export const PATH_CHALLENGES = '/challenges';
export const PATH_CHALLENGES_LEADERBOARD = '/challenges/:id/leaderboard';
export const PATH_SHOUTOUTS = '/shout-outs';
export const PATH_ASSIGNMENTS = '/assignments';
// export const PATH_FORYOU = '/foryou';
export const PATH_STUDIO_LINK = '/studio/:id';
export const PATH_RESOURCES = '/resource';
export const BACKUP_PATH_RESOURCES = '/resources';
export const PATH_RESOURCE_DETAILS = '/resource/:id';
export const BACKUP_PATH_RESOURCE_DETAILS = '/resources/:id';
export const BACKUP_PATH_RESOURCE_DETAILS2 = '/quickref/:id';
export const PATH_NEWSLETTERS = '/newsletters';
export const PATH_AUDIT_FORMS = '/auditforms';
export const PATH_RECENT_RESOURCES = '/recent-resources/:unitId';
export const PATH_RESOURCE_CHECKLIST = '/checklist/:id';
export const PATH_SAFETY_REPORT = '/safetycard/:id';
export const PATH_ANNOUNCEMENTS = '/announcements/:unitId';
export const PATH_ANNOUNCEMENT_QUICKPOST = '/quickpost/:id';
export const PATH_ANNOUNCEMENT_DIRECT = '/announcement/:id';
export const PATH_ANNOUNCEMENT_SHORT = '/ann/:id';
export const PATH_NEWSLETTER = '/newsletter/:id';

export const PATH_MANAGE_OVERVIEW = '/manage/overview';
export const PATH_MANAGE_ASSIGNMENTS = '/manage/assignments';
export const PATH_MANAGE_NEW_ASSIGNMENT = '/manage/assignments/new';
export const PATH_MANAGE_NEW_ASSIGNMENT_BACKUP = '/manage/assignments/new_assignment';
export const PATH_MANAGE_ASSIGNMENT_DETAILS = '/manage/assignments/:id';
export const PATH_MANAGE_ASSIGNMENT_DETAILS_BACKUP = '/manage/assignment/:id'; // deprecated?
export const PATH_MANAGE_ADD_ASSIGNMENT_USERS = '/manage/assignments/:id/add_users';
export const PATH_MANAGE_ADD_ASSIGNMENT_USERS_BACKUP = '/manage/add_assignment_users/:id'; // deprecated?
export const PATH_MANAGE_FEEDBACK = '/manage/feedback';
export const PATH_MANAGE_REPORTING = '/manage/reporting/summary';
export const PATH_MANAGE_REPORTING_ACTIVITY = '/manage/reporting/activity';
export const PATH_MANAGE_REPORTING_ACTIVITY_DETAILS = '/manage/reporting/activity/:id';
export const PATH_MANAGE_REPORTING_ANNOUNCEMENTS = '/manage/reporting/announcements';
export const PATH_MANAGE_REPORTING_ANNOUNCEMENT_DETAILS = '/manage/reporting/announcements/:id';
export const PATH_MANAGE_REPORTING_AUDITS = '/manage/reporting/audits';
export const PATH_MANAGE_REPORTING_AUDIT_DETAILS = '/manage/reporting/audits/:id';
export const PATH_MANAGE_REPORTING_FORMS = '/manage/reporting/forms';
export const PATH_MANAGE_REPORTING_FORM_DETAILS = '/manage/reporting/forms/:id';
export const PATH_MANAGE_REPORTING_USERS = '/manage/reporting/users';
export const PATH_MANAGE_REPORTING_USER_ACTIVITY_ASSIGNMENTS = '/manage/reporting/users/:id/assignments';
export const PATH_MANAGE_REPORTING_USER_ACTIVITY_RESOURCE_VIEWS = '/manage/reporting/users/:id/resource-views';
export const PATH_MANAGE_REPORTING_USER_ACTIVITY_FORMS = '/manage/reporting/users/:id/forms';
export const PATH_MANAGE_REPORTING_USER_ACTIVITY_AUDITS = '/manage/reporting/users/:id/audits';
export const PATH_MANAGE_REPORTING_USER_ACTIVITY_SHOUTOUTS = '/manage/reporting/users/:id/shout-outs';
// export const PATH_MANAGE_REPORTING_EXPORTS = '/manage/reporting/exports';
export const PATH_MANAGE_RESOURCE_EDIT = '/manage/resource/edit/:id';
// export const PATH_MANAGE_RESOURCES = '/manage/resources';
export const PATH_MANAGE_USERS = '/manage/users';
export const PATH_MANAGE_EDIT_USER = '/manage/users/:id';
export const PATH_MANAGE_EDIT_USER_BACKUP = '/manage/users/:id'; // deprecated?
export const PATH_MANAGE_BATCH_INVITE = '/manage/users/batch_invite';
export const PATH_MANAGE_ANNOUNCEMENTS = '/manage/announcements';
export const PATH_MANAGE_NEW_ANNOUNCEMENT = '/manage/announcements/new';
export const PATH_MANAGE_NEW_ANNOUNCEMENT_BACKUP = '/manage/announcements/new_announcement'; // deprecated?
export const PATH_MANAGE_ANNOUNCEMENT_EDIT = '/manage/announcements/:id';
export const PATH_MANAGE_ANNOUNCEMENT_EDIT_BACKUP = '/manage/announcement/:id'; // deprecated?
export const PATH_MANAGE_HELP = '/manage/help/:id';
export const PATH_MANAGE_NEWSLETTERS = '/manage/newsletters';
export const PATH_MANAGE_NEW_NEWSLETTER = '/manage/newsletters/new';
export const PATH_MANAGE_NEWSLETTER_EDIT = '/manage/newsletters/:id';
export const PATH_MANAGE_SUGGESTED_RESOURCES = '/manage/suggested-resources';
export const PATH_MANAGE_SUGGESTED_RESOURCES_EDIT = '/manage/suggested-resources/:id';
export const PATH_PRINT_QR_CODES = '/manage/print-qr-codes';
export const PATH_MANAGE_PRODUCTION_REQUEST = '/manage/production-request/';
export const PATH_MANAGE_PRODUCTION_REQUEST_ANNOUNCEMENT = '/manage/production-request/:id';
export const PATH_MANAGE_RESOURCE_REVIEW = '/manage/review';
export const PATH_MANAGE_RESOURCE_REVIEW_FORM = '/manage/review/:id';
export const PATH_MANAGE_RESOURCE_NETWORK = '/manage/network';
export const PATH_MANAGE_RESOURCE_NETWORK_SEARCH = '/manage/network/search';
export const PATH_MANAGE_RESOURCE_NETWORK_UPDATED = '/manage/network/updated';
export const PATH_MANAGE_RESOURCE_NETWORK_UPDATED_BY_UNIT = '/manage/network/updated/:name';
export const PATH_MANAGE_RESOURCE_NETWORK_WISH_LIST = '/manage/network/wish-list';
export const PATH_MANAGE_NETWORK_PREVIEW = '/manage/network/:id';

export const PATH_PROFILE = '/profile';

export const DEPRECATED_PATHS = {
	'/pins': PATH_BOOKMARKS,
	'/recognition': PATH_SHOUTOUTS,
	'/shoutouts': PATH_SHOUTOUTS,
};

export type RegState = 'registered' | 'verifying' | 'invited' | 'self-registered';

export const REG_STATES = {
	REGISTERED: 'registered',
	VERIFYING: 'verifying',
	INVITED: 'invited', // deprecated, use VERIFYING instead
	SELF_REGISTERED: 'self-registered', // deprecated, use VERIFYING instead
};

// This should really receive RegState, but if it gets a different string, it'll handle it.
export function displayRegState(regState: RegState | undefined, deactivated?: boolean): string {
	if (deactivated) {
		return 'Deactivated';
	}
	switch (regState) {
		case REG_STATES.VERIFYING:
		case REG_STATES.INVITED:
		case REG_STATES.SELF_REGISTERED:
			return 'Invited';
		case REG_STATES.REGISTERED:
			return 'Registered';
		default:
			return 'Invalid';
	}
}

export type RegSource = 'self' | 'sso' | 'invited' | 'uploaded' | 'imported';

export function displayRegSource(regSource: RegSource | undefined) {
	switch (regSource) {
		case 'self':
			return 'Self-registered';
		case 'sso':
			return 'Self-registered';
		case 'invited':
			return 'Invited';
		case 'uploaded':
			return 'Uploaded';
		case 'imported':
			return 'Automated import';
		default:
			return regSource ? 'Invalid' : 'Unknown';
	}
}

export const NEW_ID = 'NEW';

export enum FEEDBACK_TYPES {
	USEFUL = 'This is useful',
	NOT_ACCURATE = 'The information is not accurate',
	MISSING_INFO = 'There is missing information',
	OTHER = 'Something else',
}

export type Feedback = keyof typeof FEEDBACK_TYPES;

export const BODY_CLASS_FOR_NON_MODAL_PAGES = 'private-body';

// A general check for mobile-device type functionality
// renamed to not conflict with isMobileDevice from @elemeno/ui
export const isOnMobileDevice: boolean = (() => {
	// Force to true to test "keep me logged in"
	// if (isOffline) return true;

	if (!navigator) return false;

	if ('maxTouchPoints' in navigator) {
		return navigator.maxTouchPoints > 0;
	} else if ('msMaxTouchPoints' in navigator) {
		return (navigator['msMaxTouchPoints'] as number) > 0;
	} else {
		const mQ = window['matchMedia'] && matchMedia('(pointer:coarse)');
		if (mQ && mQ.media === '(pointer:coarse)') {
			return !!mQ.matches;
		} else if ('orientation' in window) {
			return true; // deprecated, but good fallback
		} else if ('userAgent' in navigator) {
			// Only as a last resort, fall back to user agent sniffing
			const UA = navigator['userAgent']; // using quote notation avoid typescript complaint about 'userAgent' not existing on type 'never'
			return /\b(BlackBerry|webOS|iPhone|IEMobile|Android|Windows Phone|iPad|iPod|Mobile\/[0-9A-Z]+)\b/i.test(UA);
			// note that "Mobile/15E148" is something I'm seeing in Chrome when using device preview for mobile formats
		} else {
			return false;
		}
	}
})();
