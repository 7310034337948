import { DEPRECATED_PATHS } from 'helpers/constants';

const isLocalHost = (hostname: string): boolean =>
	!!(
		hostname === 'localhost' ||
		hostname === '[::1]' ||
		hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/)
	);

function handlePRs(url: URL): void {
	const prPathParam = '#prpath=';

	// if hash path starts with prPathParam, append to pathname
	if (url.hash && url.hash.indexOf(prPathParam) === 0) {
		// append hash path to pathname
		url.pathname += '/' + url.hash.substring(prPathParam.length);

		// reset hash
		url.hash = '';
	}
}

function handleOldHashPath(url: URL): void {
	// remove # from hash and split into path and query params
	const [hashPath, hashQueryParams] = url.hash.substring(1).split('?');

	// update pathname
	url.pathname = hashPath;

	// reset hash
	url.hash = '';

	// update query params
	if (hashQueryParams) {
		url.search = '?' + hashQueryParams;
	}
}

const DEPRECATED_PATH_REGEXPS = Object.entries(DEPRECATED_PATHS).map(([badPath, goodPath]) => ({
	re: new RegExp(`^${badPath}([/?].*)?$`),
	path: goodPath,
}));

function handleDeprecatedPaths(url: URL): void {
	for (const { re, path } of DEPRECATED_PATH_REGEXPS) {
		const rewrite = url.pathname.replace(re, `${path}$1`);
		if (rewrite !== url.pathname) {
			url.pathname = rewrite;
			return;
		}
	}
}

export const linkRewriting = (): void => {
	const url = new URL(window.location.href);

	// redirect to https when not localhost
	if (url.protocol === 'http:' && !isLocalHost(url.hostname)) {
		url.protocol = 'https';
	}

	// update path on PR redirect, before loading router
	if (url.host.includes('.prs.dev.')) {
		handlePRs(url);
	}

	// if is old hash path, replace with new path
	if (url.pathname === '/' && url.hash.startsWith('#/')) {
		handleOldHashPath(url);
	}

	handleDeprecatedPaths(url);

	// transform scrollTo query param to hash
	const scrollTo = url.searchParams.get('scrollTo');
	if (scrollTo) {
		// add scrollTo to hash
		url.hash = scrollTo;

		// remove scrollTo from query params
		url.searchParams.delete('scrollTo');
	}

	// force redirect if protocol changes
	if (url.protocol !== window.location.protocol) {
		location.replace(url);
	} else if (url.toString() !== window.location.toString()) {
		// update url without rerender
		window.history.replaceState({}, '', url);
	}
};
